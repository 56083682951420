<template>
    <div class="cms">
        <div v-if="the_page">
            <slot></slot>
            <div v-if="hasPermission(['manage:Content'])">
                <div style="position: absolute; right: 0; display:inline-block;">
                    <p class="mr-4 mb-2 body" >status: {{the_page.status}}<span v-if="the_page.status == 'publish'">ed</span></p>
                    <v-btn fab absolute right center class="cms-edit-btn" :to="{name : 'CMSEditor', 'params' : {page_id : the_page.id} }">
                        <v-icon small class="mr-2">fas fa-pencil-alt</v-icon> edit
                    </v-btn>
                </div>
            </div>
            <div v-for="(block, key) in the_page.blocks" :key="key" :class="blockClasses(block, 'my-7')" :style="blockStyles(block)">
                <div v-if="showBlock(block)" :is="block.type" :content-data="block"></div>
            </div>
        </div>
        <v-progress-linear v-else indeterminate color="primary"></v-progress-linear>
    </div>
</template>
<script>
import index from './index.vue';

export default {
    extends: index
}

</script>
